import _components from "@components"
import modules from "@modules"

import browser from "../builder.browser"
import ssr from "../builder.ssr"

// ADAPTED CORE ELEMS
import { mapLibrary, SiteProvider } from "@adapters"

// TEMPLATES
import * as templates from "@templates"

const components = mapLibrary({ ..._components, ...modules })

const ONE_TRUST_ID_IENYC = "01914556-fbc9-7d1a-be41-9957bf7de126"
const IENYC_DOMAINS = [
  "landing-qa.ienyc.edu",
  "www.ienyc.edu",
  "www.landing.ienyc.edu",
  "landing.ienyc.edu",
  "qa.ie-nyc.org",
  "qa.thesaurus.ie-nyc.org",
  "qa.ie.edu",
  "qa.ienyc.edu",
  "qa.thesaurus.ienyc.edu",
  "www.ie-nyc.org",
  "ie-nyc.org",
  "www.ienyc.edu",
  "ienyc.edu",
  "www.thesaurus.ienyc.edu",
  "thesaurus.ienyc.edu",
  "www.thesaurus.ie-nyc.org",
  "thesaurus.ie-nyc.org",
  "www.thesaurus.ie.edu",
  "thesaurus.ie.edu",
]

function changeScript() {
  if (typeof window !== "undefined") {
    const currentDomain =
      typeof window !== "undefined" &&
      window?.location &&
      window?.location?.hostname

    const scriptTags = document?.querySelectorAll("script[delay]")

    scriptTags.forEach(function (scriptTag) {
      if (IENYC_DOMAINS.includes(currentDomain)) {
        scriptTag.setAttribute("data-domain-script", ONE_TRUST_ID_IENYC)
      }
    })
  }
}

changeScript()

//Dimensions
const generateAutomaticDimensions = props => ({})

export {
  templates,
  SiteProvider,
  components,
  ssr,
  browser,
  generateAutomaticDimensions,
}
