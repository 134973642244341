import { PopUp, PopUp2x } from "@thumbnails/Modules"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "module",
  displayName: "Pop up",
  component: "PopUp",
  category: "content",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Type of pop up",
          type: "ConditionalField",
          key: "popUpType",
          mandatory: true,
          required: true,
          options: [
            {
              value: "image",
              title: "Image",
              name: "image",
            },
            {
              value: "form",
              title: "Form",
              name: "form",
            },
          ],
          fields: [
            {
              title: "Title form submitted",
              type: "TextField",
              key: "titleOpened",
              mandatory: true,
              helptext:
                "This title will appear when the user does submit the Hubspot form.",
              humanReadable: true,
              condition: "form",
            },
            {
              title: "Form",
              type: "ComponentContainer",
              mandatory: true,
              whiteList: ["IntroFormElement"],
              key: "componentModules",
              condition: "form",
            },
            {
              title: "Image",
              type: "ImageField",
              key: "image",
              hideable: true,
              condition: "image",
              helptext:
                "Recomended 415x415px minimum; 830x830px optimal; 1245x1245px excellent",
            },
            {
              title: "Image Link",
              type: "UrlField",
              key: "imageLink",
              advanced: true,
              hideable: true,
              condition: "image",
            },
            {
              title: "Button",
              type: "ComponentContainer",
              condition: "image",
              whiteList: ["Link"],
              key: "button",
              hideable: true,
            },
            {
              title: "Claim text",
              condition: "image",
              type: "TextField",
              key: "claimText",
              hideable: true,
              humanReadable: true,
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "When to show",
          type: "ConditionalField",
          key: "whenToShow",
          mandatory: true,
          required: true,
          options: [
            {
              value: "pageLoads",
              title: "When page loads",
              name: "pageLoads",
            },
            {
              value: "afterDelay",
              title: "After delay",
              name: "afterDelay",
            },
            {
              value: "scrollingThePage",
              title: "Scrolling the page",
              name: "scrollingThePage",
            },
            {
              value: "exitIntent",
              title: "Exit intent",
              name: "exitIntent",
            },
          ],
          fields: [
            {
              title: "Seconds delay",
              type: "NumberField",
              key: "secondsDelay",
              default: 5,
              mandatory: true,
              condition: "afterDelay",
            },
            {
              title: "Percentage scrolled",
              type: "NumberField",
              key: "percentageScrolled",
              default: 50,
              mandatory: true,
              condition: "scrollingThePage",
            },
            {
              title: "Number of times",
              type: "NumberField",
              key: "numberOfTimes",
              helptext:
                "Number of times the popup will appear after it has been closed for the first time.",
              default: 0,
              mandatory: true,
              condition: "exitIntent",
            },
          ],
        },
        {
          title: "Whom to show",
          type: "RadioGroup",
          key: "whomToShow",
          mandatory: true,
          options: [
            {
              value: "firstTimeVisitors",
              title: "First-time visitors",
              name: "firstTimeVisitors",
            },
            {
              value: "returningVisitors",
              title: "Returning visitors",
              name: "returningVisitors",
            },
            {
              value: "everyone",
              title: "Everyone",
              name: "everyone",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "PopUp",
    popUpType: "image",
    image: { ...DEFAULT_IMAGE_FIELD },
    claimText: "Optional text accompanying the image and the button.",
    whenToShow: "exitIntent",
    numberOfTimes: 0,
    whomToShow: "everyone",
    button: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        url: "",
        href: null,
        linkTo: null,
        newTab: true,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    componentModules: {
      component: "IntroFormElement",
      formTitle: "Title",
      formImage: {
        component: "Image",
      },
    },
  },
  thumbnails: {
    "1x": PopUp,
    "2x": PopUp2x,
  },
}
